// TopImageComponent.tsx

import React from 'react';
import logo from '../assets/logo.png'; // ロゴ画像のパスをインポート
import './TopImageComponent.css'; // 必要に応じてスタイルシートをインポート

const TopImageComponent = () => {
  return (
    <div className="container">
      <div className="header">
        <h1>点検AI</h1>
        <p>点検AI用ツールと情報。</p><br />
      </div>
      <div className="logo-section">
        <p><img src={logo} alt="Logo" className="logo-image" /></p>
        <p><a href="./change-detection-app/">差分検知</a></p>--（アップロードコンテンツは公開されています） {/* ここに表示したいコンテンツを追加 */}
      </div>
      <div className="content">
        {/* その他のコンテンツ */}
      </div>
    </div>

  );
};

export default TopImageComponent;